<template>
  <div class="information">
    <el-main class="wit-information-container">
      <img-title-panel bg-img="/images/information/ai1.png" height="600px" mask-color="rgba(0,0,0,0.7)">
        <div class="page-title">记录AI改变世界的瞬间</div>
      </img-title-panel>
      <news-center @ContentUpdated="ContentUpdated"></news-center>
    </el-main>
  </div>
</template>

<script>
  import ImgTitlePanel from "@/components/content/ImgTitlePanel/ImgTitlePanel";
  import NewsCenter from "@/views/information/comps/NewsCenter/NewsCenter";
  export default {
    name: "Information",
    components:{
      ImgTitlePanel,
      NewsCenter
    },
    methods:{
      ContentUpdated(){
        this.$emit('ContentUpdated')
      }
    },
    mounted() {
      this.$emit('ContentMounted')
    },
    activated() {
      this.$emit('ContentActivated')
    }
  }
</script>

<style scoped>
  .information .wit-information-container{
    padding: 0 !important;
  }
</style>
