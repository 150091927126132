<template>
  <wit-block-content-container class="news-center">
    <div class="panel-title">
      资讯中心
    </div>
    <div class="news-panel-container">
      <news-panel v-for="(item, index) in topNews" :key="'top_'+index"
                  panel-type="expand"
                  :news-img-src="item.titleImg"
                  :news-title="item.newsTitle"
                  :news-time="item.newsTime"
                  :news-abstract="item.newsAbstract"></news-panel>
      <news-panel v-for="(item, index) in newsInfo" :key="index"
                  :news-img-src="item.titleImg"
                  :news-title="item.newsTitle"
                  :news-time="item.newsTime"
                  :news-abstract="item.newsAbstract"></news-panel>
    </div>
  </wit-block-content-container>
</template>

<script>
  import WitBlockContentContainer
    from "@/components/common/WitBaseUI/WitBlockContentContainer/WitBlockContentContainer";
  import NewsPanel from "@/components/content/NewsPanel/NewsPanel";
  import {getNewsData} from "@/network/news";
  export default {
    name: "NewsCenter",
    components:{
      WitBlockContentContainer,
      NewsPanel
    },
    created() {
      getNewsData().then(response => {
        this.newsData = response
      })
    },
    data(){
      return{
        newsData: null,
        // topNews:[
        //   {
        //     imgSrc: '/images/information/new1.jpg',
        //     newsTitle: '沃丁科技发布企业级人工智能解决方案--昆仑',
        //     newsTime: '2020-12-01',
        //     newsAbstract: `2020世界5G大会期间，沃丁科技发布全新智慧出行解决方案，
        //                    包括可提供基于固定路线点到点接驳服务的L4级智能网联接驳小巴，
        //                    以及具备多传感器融合感知能力的智能路侧感知解决方案，
        //                    推动智慧出行产业“车、路”两端的智能化升级及场景应用。`
        //   }
        // ],
        // newsInfo:[
        //   {
        //     imgSrc: '/images/information/new1.jpg',
        //     newsTitle: '沃丁科技发布企业级人工智能解决方案--昆仑',
        //     newsTime: '2020-12-01',
        //     newsAbstract: `2020世界5G大会期间，沃丁科技发布全新智慧出行解决方案，
        //                    包括可提供基于固定路线点到点接驳服务的L4级智能网联接驳小巴，
        //                    以及具备多传感器融合感知能力的智能路侧感知解决方案，
        //                    推动智慧出行产业“车、路”两端的智能化升级及场景应用。`
        //   }
        // ]
      }
    },
    computed:{
      topNews(){
        if (this.newsData) {
          return this.newsData.filter(item => {return item.isTop === 1})
        } else {
          return []
        }

      },
      newsInfo(){
        if (this.newsData) {
          return this.newsData.filter(item => {return item.isTop === 0})
        } else {
          return []
        }
      }
    },
    updated() {
      this.$emit('ContentUpdated')
    }
  }
</script>

<style scoped>
  .news-center{
    margin: 100px 0;
  }
  .news-center .panel-title{
    width: 100%;
    padding: 0 15px 40px;
  }
</style>
