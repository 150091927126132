<template>
  <div class="news-panel" :class="{'mini': !isExpanded, 'expand': isExpanded, 'item-center': isExpanded}">
    <div class="news-img-block">
      <a href="javascript:void(0)" class="news-img-link">
        <img :src="newsImgSrc" height="100%" class="news-img" width="100%" alt="">
      </a>
    </div>
    <div class="news-info-block w-l-column-rs-compact"
         :class="{'info-hover': isMiniInfoHover, 'w-l-jc-between': isExpanded, 'w-l-jc-start': !isExpanded}"
         @mouseenter="isMiniInfoHover=true"
         @mouseleave="isMiniInfoHover=false">
      <div class="title">
        <a href="javascript:void(0)" class="ellipsis ellipsis-2">
          {{newsTitle}}
        </a>
      </div>
      <div class="time" v-if="isExpanded">
        {{ newsTime }}
      </div>
      <div class="abstract ellipsis"
           :class="{'ellipsis-4': isExpanded, 'ellipsis-2': !isExpanded}"
           v-show="!isDescHide">
        {{newsAbstract}}
      </div>
      <div class="decoration font-144 fc-lightgray" v-if="isExpanded">
        ”
      </div>
    </div>
    <div class="time" v-if="!isExpanded">
      {{ newsTime }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "NewsPanel",
    props:{
      panelType:{
        type: String,
        default:() => 'mini'
      },
      newsImgSrc:{
        type: String,
        default:() => ''
      },
      newsTitle:{
        type: String,
        default:() => ''
      },
      newsTime:{
        type: String,
        default:() => ''
      },
      newsAbstract:{
        type: String,
        default:() => ''
      }
    },
    data(){
      return{
        isMiniInfoHover: false
      }
    },
    computed:{
      isExpanded(){
        return this.panelType === 'expand'
      },
      isDescHide(){
        return !this.isExpanded && !this.isMiniInfoHover
      }
    }
  }
</script>

<style scoped>
  .news-panel{
    width: 33%;
    height: 450px;
    position: relative;
    margin: 30px 15px;
  }
  .news-panel.mini{
    width: 30%;
    height: 380px;
  }
  .news-panel.expand{
    width: 100%;
    height: 380px;
  }
  .news-panel .news-img-block{
    overflow: hidden;
  }
  .news-panel .news-img-block a{
    width: 100%;
    height: 100%;
  }
  .news-panel .news-img-block .news-img{
    object-fit: cover;
    transform: scale(1,1);
    -webkit-transform: scale(1,1);
    transform-origin: center center;
    -webkit-transform-origin: center center;
    transition: .5s all;
  }
  .news-panel:hover .news-img-block .news-img{
    transform: scale(1.02,1.02);
    -webkit-transform: scale(1.02,1.02);
  }
  .news-panel.expand .news-img-block{
    width: 50%;
    height: 100%;
  }
  .news-panel.expand .news-info-block{
    width: 50%;
    height: 100%;
    padding: 50px 40px 0 40px;
    border-top: lightgray solid 1px;
    border-right: lightgray solid 1px;
    border-bottom: lightgray solid 1px;
  }
  .news-panel.mini .news-img-block{
    width: 100%;
    height: 65%;
  }
  .news-panel.mini .news-info-block{
    width: 100%;
    height: 25%;
    border-left: lightgray solid 1px;
    border-right: lightgray solid 1px;
    position: absolute;
    bottom: 10%;
    padding: 30px 25px 0 25px;
    background-color: white;
    transition: 0.5s all;
  }
  .news-panel.mini .info-hover{
    height: 40% !important;
  }
  .news-panel.mini .time{
    position: absolute;
    bottom: 0;
    border-left: lightgray solid 1px;
    border-right: lightgray solid 1px;
    border-bottom: lightgray solid 1px;
    height: 10%;
    width: 100%;
    padding-left: 25px;
  }
  .news-panel .news-info-block .title{
    margin-bottom: 10px;
  }

  .news-panel.mini .news-info-block .title a:hover,
  .news-panel .news-info-block .title a:hover{
    color: var(--color-tint);
  }
  .news-panel.mini .news-info-block .title a{
    line-height: 1.71;
    font-size: 16px;
    color: black;
  }
  .news-panel.expand .news-info-block .title a{
    line-height: 1.4;
    font-size: 30px;
  }
  .news-panel .time{
    color: #ccc;
    font-size: 12px;
  }
  .news-panel .news-info-block .abstract{
    line-height: 1.71;
  }
  .news-panel.mini .news-info-block .abstract{
    color: #7d7d7d;
    transition: 0.5s all;
  }
  .news-panel .decoration{
    width: 100%;
    text-align: right;
    height: 60px;
    line-height: 120px;
  }
</style>
